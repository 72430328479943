import { navigate } from 'gatsby';
import { useEffect } from 'react';
import LoggedInTeams from '../../utils/LoggedInTeams';

const CHECK_TEAM_INTERVAL = 500;

export default () => {
  useEffect(() => {
    const interval = setInterval(() => {
      const hosts = LoggedInTeams.getHosts();

      if (hosts.length > 0) {
        navigate('/app/desktop/tabbar');
      }
    }, CHECK_TEAM_INTERVAL);

    return () => clearInterval(interval);
  }, []);

  return null;
};
